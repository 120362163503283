export const startQuiz = [
  {
    question:
      '... wie viel kostet 10x Smartphone Aufladen auf deiner Stromrechnung?',
    answer: 3,
    less: 'Uiuiui, zu wenig. Versuch es nochmal.',
    much: 'Zu viel! Versuch es nochmal.',
    correct: 'Richtig. Nur geraten?',
    info:
      'Weil: 1x Aufladen entsprechen etwa 0,01 kWh und 1kWh kostet ungefähr 30 Cent.',
  },
  {
    question:
      'Welcher Energieverbrauch ist in einem typischen deutschen Haushalt größer: Strom oder Heizung?',
    answer: 'Heizung',
    wrong: 'Wer hätte das gedacht, es ist Heizung.',
    correct: 'Genau!',
  },
  {
    question:
      'Wie lange musst du Radfahren, damit du genug Energie erzeugt hast, um abends einen Film anzusehen?',
    answer: 30,
    less: 'Das ist zu wenig.',
    much: 'Zu viel!',
    correct: 'Super!',
    info:
      'Ein 90 minütiger Film, 40 Watt Leistung des Fernseher und 100 Watt Leistung des Radfahrers ergeben ca. eine halbe Stunde.',
  },
  {
    question:
      'Ab wieviel km überholt die Energiebilanz eines typischen eAuto (beim aktuellen deutschen Strommix), den eines Verbrenners?',
    answer: '30.000km',
    wrong: 'Nope',
    correct: 'yesss',
    info: 'Hintergrund: in der Produktion fällt für ein eAuto mehr Energie an',
  },
  {
    question:
      'Wie oft könnt ihr die Strecke München ↔ Gardasee mit dem Auto zurücklegen für dieselbe CO2-Bilanz wie ein gemeinsamer Flug nach Thailand?',
    answer: 100,
    less: 'Zu wenig. Versuch es nochmal.',
    much: 'Zu viel! Versuch es nochmal.',
    correct: 'Richtig!',
    info:
      'Für zwei Personen sind 8800 km vier mal fällig, also 35.000 Flugkilometer. Wenn man 350 Gramm pro Personen-Kilometer ansetzt, dann sind das gut 12.250 kg CO2. Bei einem Auto (egal, wieviele drinsitzen) setzt man so 150 Gramm pro Auto-Kilometer an, hin und zurück von München an den Gardasee sind es 850 Kilometer, man kommt also auf 127.5 kg. Teilt man die zwei Zahlen durcheinander, dann kommt man auf 96.',
  },
  {
    question:
      'Wie lange kannst du Fernsehen mit der Energie, die ein typischer Heizpilz an einem Abend verbraucht?',
    answer: 4,
    wrong: 'Nope',
    correct: 'yesss',
  },
  {
    question:
      'Wie oft könntest du dein Smartphone Aufaden für 1 km Fahren mit einem Elektroauto?',
    answer: 15,
    less: 'Zu wenig. Versuch es nochmal.',
    much: 'Zu viel! Versuch es nochmal.',
    correct: 'Richtig!',
    info:
      'Einmal Handy aufladen ca. 0,01 kWh. Sparsames E-Auto 15 kWh pro 100 km, also 0,15 kWh pro Kilometer. Ich würde unbedingt das E-Auto nehmen, sonst wird Primärenergie mit elektrischer Energie verglichen und das ist angreifbar.',
  },
  {
    q:
      'Wieviele Avocados müsste man Essen, um genauso viel Wasser verbraucht zu haben, wie für die Lithium-Produktion einer vollen Tesla Autobatterie benötigt wurde?',
    a: '11 Stück',
    n: 'Nope',
    y: 'yesss',
    i:
      '8. Bei eAutos wird häufig der hohe Wasserverbrauch für die Batterieproduktion kritisiert. Wieviele Avocados müsste man Essen, um genauso viel Wasser verbraucht zu haben, wie für die Lithium-Produktion einer vollen Tesla Autobatterie benötigt wurde? Antwort: 11 Stück. Und wieviel Rindfleisch? Antwort: 250g',
  },
];

export const endingQuestion = ['Können wir die Energiewende schaffen?'];
