/* eslint-disable no-plusplus */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Matter from 'matter-js';
import PropTypes from 'prop-types';
import { useWindowSize } from '../../hooks/useWindowSize';
import CentFront from '../../assets/images/cent-front.png';

const FallingItems = ({ balls }) => {
  const boxRef = useRef(null);
  const canvasRef = useRef(null);

  const { Engine, Render, World, Bodies } = Matter;

  const size = useWindowSize();
  const { width, height } = size;

  const transparentColor = 'rgba(0,0,0,0)';
  const wallsWidth = 100;

  useEffect(() => {
    const engine = Engine.create({});
    const { world } = engine;
    const render = Render.create({
      element: boxRef.current,
      engine,
      canvas: canvasRef.current,
      options: {
        width,
        height,
        background: 'rgba(255, 0, 0, 0.0)',
        wireframes: false,
      },
    });

    const newBall = () => {
      const ball = Bodies.circle(width / 2, 0, 10, {
        restitution: 2,
        render: {
          sprite: {
            texture: CentFront,
            xScale: 0.3,
            yScale: 0.3,
          },
        },
      });
      return World.add(world, ball);
    };

    const floor = Bodies.rectangle(width / 2, height, width, wallsWidth * 2, {
      isStatic: true,
      render: {
        fillStyle: transparentColor,
      },
    });

    const wallLeft = Bodies.rectangle(0, height / 2, wallsWidth, height, {
      isStatic: true,
      render: {
        fillStyle: transparentColor,
      },
    });

    const wallRight = Bodies.rectangle(width, height / 2, wallsWidth, height, {
      isStatic: true,
      render: {
        fillStyle: transparentColor,
      },
    });

    World.add(world, floor);
    World.add(world, wallLeft);
    World.add(world, wallRight);

    for (let index = 0; index < balls; index++) {
      setTimeout(() => newBall(), `${index}00`);
    }

    Engine.run(engine);
    Render.run(render);
  }, [balls]);

  return (
    <StyledSketch ref={boxRef} className="fallingitems">
      <canvas ref={canvasRef} width={width} height={height} />
    </StyledSketch>
  );
};

const StyledSketch = styled.div`
  position: absolute;
  top: -100px;
  z-index: -1;
  overflow-x: hidden;
`;

FallingItems.propTypes = {
  balls: PropTypes.array,
};

export default FallingItems;
