import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Right from '../../assets/svg/right.svg';

export const IlluButtonLeft = ({ children, onClick }) => (
  <button
    className="spacing-inner"
    type="button"
    style={{ width: '50%' }}
    onClick={onClick}
  >
    {children}
  </button>
);

export const IlluButtonRight = ({ children, onClick }) => (
  <button
    className="spacing-inner"
    type="button"
    style={{ width: '50%', borderRight: 'var(--border)' }}
    onClick={onClick}
  >
    {children}
  </button>
);

export const Button = ({ text, onClick, disabled, left }) => (
  <StyledButton
    disabled={disabled}
    onClick={onClick}
    style={{
      borderRight: left && 'none',
    }}
  >
    {text}
  </StyledButton>
);

const StyledButton = styled.button`
  font-size: var(--fontsize-2);
  border: var(--border);
  height: 5rem;
  padding: 0 var(--spacing-XS);
  background: var(--bg-light-color);
  width: 100%;
`;

export const SubmitButton = ({ onClick, disabled }) => (
  <StyledSubmitButton
    type="submit"
    aria-label="submit"
    disabled={disabled}
    onClick={onClick}
  >
    <Right />
  </StyledSubmitButton>
);

const StyledSubmitButton = styled.button`
  font-size: var(--fontsize-2);
  border: var(--border);
  height: 5rem;
  padding: 0 var(--spacing-XS);
  display: flex;
  align-items: center;
  background: var(--user-color);
  width: auto;
  border-left: none;

  &:hover {
    svg {
      transform: rotate(-45deg);
    }
  }

  svg {
    transition: all 0.1s ease-out;
    width: 20px;
    height: auto;
  }
`;

IlluButtonLeft.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

IlluButtonRight.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  left: PropTypes.bool,
};

SubmitButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
