import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const Preview = ({ children, active }) =>
  active && (
    <StyledPreview
      initial={{ height: 0 }}
      animate={{ height: active && 'auto' }}
    >
      {children}
    </StyledPreview>
  );

const StyledPreview = styled(motion.div)`
  border: var(--border);
  border-bottom: none;
  background: var(--user-color);
  padding: var(--v-spacing-S);
  display: flex;
  flex-wrap: wrap;
`;

Preview.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
};

export default Preview;
