import { useEffect } from 'react';

export const useBottomScroll = () => {
  useEffect(() => {
    document.querySelector('main').scrollIntoView({
      block: 'end',
      behavior: 'smooth',
    });
  });
};
