import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const InputButton = ({ children }) => (
  <StyledInputButton>{children}</StyledInputButton>
);

const StyledInputButton = styled.div`
  font-size: var(--fontsize-2);
  border: var(--border);
  height: 5rem;
  padding: 0 var(--spacing-XS);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--bg-light-color);
`;

InputButton.propTypes = {
  children: PropTypes.node,
};

export default InputButton;
