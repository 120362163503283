import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import InputButton from './inputButton';

const RangeSlider = ({ onChange, value }) => (
  <InputButton>
    <StyledRangeSlider
      type="range"
      min="0"
      max="200"
      defaultValue={value}
      onChange={onChange}
      name="amountInput"
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label htmlFor="amountInput" />
  </InputButton>
);

const StyledRangeSlider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: var(--border-width);
  background: var(--ee-color);
  border-radius: 50px;
  outline: none;

  label {
    position: absolute;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: var(--range-width);
    height: var(--range-width);
    border-radius: 50%;
    background: var(--user-color);
    border: var(--border);
    border-color: var(--user-color);
    cursor: pointer;

    &:hover {
      border-color: var(--bg-color);
      background: var(--bg-color);
    }
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    width: var(--range-width);
    height: var(--range-width);
    border-radius: 50%;
    background: var(--user-color);
    border: var(--border);
    border-color: var(--user-color);
    cursor: pointer;

    &:hover {
      border-color: var(--bg-color);
      background: var(--bg-color);
    }
  }

  &::-moz-range-progress {
    background: var(--bg-color);
    border-radius: 50px;
    height: var(--border-width);
  }
`;

RangeSlider.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default RangeSlider;
