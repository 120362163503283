import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const InputWrapper = ({ children, className }) => (
  <StyledInput
    className={className}
    initial={{ height: 0 }}
    animate={{ height: 'auto' }}
  >
    <div>{children}</div>
  </StyledInput>
);

const StyledInput = styled(motion.div)`
  position: fixed;
  bottom: 0;
  height: auto;
  border-top: var(--border);
  width: 100vw;
  background: var(--bg-color);
  margin-top: 10rem;
  z-index: 1;
`;

InputWrapper.propTypes = {
  children: PropTypes.node,
};

export default InputWrapper;
