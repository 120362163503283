/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useReducer } from 'react';
import InView from 'react-intersection-observer';
import { SubmitButton } from '../atoms/buttons';
import Delayed from '../atoms/delayed';
import { endingQuestion, startQuiz } from '../../assets/text/startQuiz';
import { useClientSide } from '../../hooks/useClientSide';
import { useCheckAnswer } from '../../hooks/useCheckAnswer';
import { ChatIllu } from '../atoms/chatMedia';
import Person from '../../assets/svg/person.svg';
import ChatLeft from '../atoms/chatLeft';
import ChatRight from '../atoms/chatRight';
import InputWrapper from '../atoms/inputWrapper';
import Preview from '../atoms/inputPreview';
import RangeSlider from '../atoms/rangeSlider';
import { ChatInfo, ChatLink } from '../atoms/chatText';

const initialState = {
  q1value: 0,
  q1a1: undefined,
  q1a2: undefined,
  q1correct: false,
  q1done: false,
  q1stored: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'Q1_INPUT':
      return {
        ...state,
        q1value: action.payload,
      };
    case 'Q1_FIRSTSUBMIT':
      return {
        ...state,
        q1value: initialState.q1value,
        q1a1: Number(state.q1value),
        q1correct: Number(state.q1value) === startQuiz[2].answer,
      };
    case 'Q1_SECONDSUBMIT':
      return {
        ...state,
        q1value: initialState.q1value,
        q1a2: Number(state.q1value),
        q1correct: Number(state.q1value) === startQuiz[2].answer,
      };
    case 'Q1_STORE':
      return {
        ...state,
        q1stored: action.payload,
        q1done: true,
      };
    default:
      throw new Error();
  }
}

const localState = JSON.parse(
  typeof window !== 'undefined' && localStorage.getItem('setTwo')
);

const SetTwo = () => {
  const isClient = useClientSide();

  const [state, dispatch] = useReducer(reducer, localState || initialState);
  const { q1value, q1a1, q1a2, q1correct, q1done, q1stored } = state;

  useEffect(
    () => isClient && localStorage.setItem('setTwo', JSON.stringify(state)),
    [isClient, state]
  );

  return (
    <div>
      {/*
       * EINS
       */}
      <div className="chats">
        {/*
         * FRAGE
         */}
        <Delayed stored={q1a1} time={500}>
          <ChatLeft stored={q1a1} time={1000} text={startQuiz[2].question} />
        </Delayed>
        <Delayed stored={q1a1} time={1500}>
          <ChatIllu stored={q1a1}>
            <Person />
          </ChatIllu>
        </Delayed>
        {/*
         * ERSTE ANTWORT USER
         */}
        {q1a1 && <ChatRight stored={q1stored} user text={`${q1a1} Minuten`} />}
        {q1a1 && (
          <Delayed stored={q1stored}>
            <ChatLeft
              stored={q1stored}
              time={1000}
              text={useCheckAnswer(
                q1a1,
                startQuiz[2].answer,
                startQuiz[2].less,
                startQuiz[2].much,
                startQuiz[2].correct
              )}
            />
          </Delayed>
        )}
        {/*
         * ZWEITE ANTWORT USER
         */}
        {q1a2 && <ChatRight stored={q1stored} user text={`${q1a2} Minuten`} />}
        {/*
         * KORREKTE ANTWORT
         */}
        {q1a2 && (
          <>
            <Delayed stored={q1stored} time={1500}>
              <ChatLeft
                stored={q1stored}
                text={
                  q1correct
                    ? startQuiz[2].correct
                    : `Es sind ${startQuiz[2].answer} Minuten.`
                }
              />
            </Delayed>
            <Delayed stored={q1stored} time={1500}>
              <InView
                triggerOnce
                onChange={(inView) =>
                  dispatch({ type: 'Q1_STORE', payload: inView })
                }
              >
                <ChatInfo stored={q1stored} text={startQuiz[2].info} />
              </InView>
            </Delayed>
          </>
        )}
        {/*
         * EINS USER INPUT
         */}
        {!(q1correct || q1done) && (
          <Delayed time={1000}>
            <InputWrapper className="spacing-inner">
              <Preview active={q1value > 0}>
                <p>{`${q1value} Minuten`}</p>
              </Preview>

              <div className="buttons">
                <RangeSlider
                  value={q1value}
                  onChange={(e) =>
                    dispatch({ type: 'Q1_INPUT', payload: e.target.value })
                  }
                />
                {!q1a1 && (
                  <SubmitButton
                    disabled={q1value === 0}
                    onClick={() => dispatch({ type: 'Q1_FIRSTSUBMIT' })}
                  />
                )}
                {q1a1 && (
                  <Delayed time={2000}>
                    <SubmitButton
                      disabled={q1value === 0}
                      onClick={() => dispatch({ type: 'Q1_SECONDSUBMIT' })}
                    />
                  </Delayed>
                )}
              </div>
            </InputWrapper>
          </Delayed>
        )}
        {/*
         * ENDE
         */}
        {(q1stored || q1correct) && (
          <>
            <Delayed time={1500}>
              <ChatLeft time={1500} text={endingQuestion[0]} />
            </Delayed>
            <Delayed time={4000}>
              <InView
                triggerOnce
                onChange={(inView) =>
                  inView && isClient && localStorage.removeItem('setTwo')
                }
              >
                <ChatLeft text="Finde die Antwort und noch mehr Infos im Buch." />
              </InView>
            </Delayed>
            <Delayed time={6000}>
              <ChatLink text="Das Buch" internalLink="/buch/" />
            </Delayed>
          </>
        )}
      </div>
    </div>
  );
};

export default SetTwo;
