import { useState, useEffect } from 'react';
import { useBottomScroll } from '../../hooks/useBottomScroll';

const Delayed = ({ children, stored = false, time }) => {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsShown(true);
      },
      stored ? 0 : time
    );
    return () => clearTimeout(timer);
  }, [time, stored]);

  useBottomScroll();

  return isShown ? children : null;
};

export default Delayed;
