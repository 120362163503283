/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useReducer } from 'react';
import InView from 'react-intersection-observer';
import {
  SubmitButton,
  IlluButtonLeft,
  IlluButtonRight,
} from '../atoms/buttons';
import InputWrapper from '../atoms/inputWrapper';
import Preview from '../atoms/inputPreview';
import Delayed from '../atoms/delayed';
import { endingQuestion, startQuiz } from '../../assets/text/startQuiz';
import { useClientSide } from '../../hooks/useClientSide';
import { useCheckAnswer } from '../../hooks/useCheckAnswer';
import { useDebounce } from '../../hooks/useDebounce';
import RangeSlider from '../atoms/rangeSlider';
import FallingItems from './fallingItems';
import { ChatIllu } from '../atoms/chatMedia';
import { ChatInfo, ChatLink } from '../atoms/chatText';
import ChatLeft from '../atoms/chatLeft';
import ChatRight from '../atoms/chatRight';
import { Strom, Waerme } from '../atoms/svgs';

const initialState = {
  q1value: 0,
  q1a1: undefined,
  q1a2: undefined,
  q1correct: false,
  q1done: false,
  q1stored: false,
  q2a: undefined,
  q2done: false,
  q2stored: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'Q1_INPUT':
      return {
        ...state,
        q1value: action.payload,
      };
    case 'Q1_FIRSTSUBMIT':
      return {
        ...state,
        q1value: initialState.q1value,
        q1a1: Number(state.q1value),
        q1correct: Number(state.q1value) === startQuiz[0].answer,
      };
    case 'Q1_SECONDSUBMIT':
      return {
        ...state,
        q1value: initialState.q1value,
        q1a2: Number(state.q1value),
        q1correct: Number(state.q1value) === startQuiz[0].answer,
      };
    case 'Q1_STORE':
      return {
        ...state,
        q1stored: action.payload,
        q1done: true,
      };
    case 'Q2_SUBMIT':
      return {
        ...state,
        q2a: action.payload,
      };
    case 'Q2_STORE':
      return {
        ...state,
        q2stored: action.payload,
        q2done: true,
      };
    default:
      throw new Error();
  }
}

const localState = JSON.parse(
  typeof window !== 'undefined' && localStorage.getItem('setOne')
);

const SetOne = () => {
  const isClient = useClientSide();

  const [state, dispatch] = useReducer(reducer, localState || initialState);
  const {
    q1value,
    q1a1,
    q1a2,
    q1correct,
    q1done,
    q1stored,
    q2a,
    q2stored,
  } = state;

  useEffect(
    () => isClient && localStorage.setItem('setOne', JSON.stringify(state)),
    [isClient, state]
  );

  return (
    <div>
      {/*
       * EINS
       */}
      <Delayed time={1000}>
        <FallingItems balls={useDebounce(q1value, 1000)} />
      </Delayed>
      <div className="chats">
        {/*
         * FRAGE
         */}
        <Delayed stored={q1a1} time={0}>
          <ChatLeft stored={q1a1} time={1000} text="Was denkst du..." />
        </Delayed>
        <Delayed stored={q1a1} time={1500}>
          <ChatLeft stored={q1a1} time={500} text={startQuiz[0].question} />
        </Delayed>
        {/*
         * ERSTE ANTWORT USER
         */}
        {q1a1 && <ChatRight stored={q1stored} user text={`${q1a1} Cent`} />}
        {q1a1 && (
          <Delayed stored={q1stored}>
            <ChatLeft
              stored={q1stored}
              time={1000}
              text={useCheckAnswer(
                q1a1,
                startQuiz[0].answer,
                startQuiz[0].less,
                startQuiz[0].much,
                startQuiz[0].correct
              )}
            />
          </Delayed>
        )}
        {/*
         * ZWEITE ANTWORT USER
         */}
        {q1a2 && <ChatRight stored={q1stored} user text={`${q1a2} Cent`} />}
        {/*
         * KORREKTE ANTWORT
         */}
        {q1a2 && (
          <>
            <Delayed stored={q1stored} time={1000}>
              <ChatLeft
                stored={q1stored}
                text={
                  q1correct
                    ? startQuiz[0].correct
                    : `Nöö! Die Antwort sind ${startQuiz[0].answer} Cent.`
                }
              />
            </Delayed>
            <Delayed stored={q1stored} time={1500}>
              <InView
                triggerOnce
                onChange={(inView) =>
                  dispatch({ type: 'Q1_STORE', payload: inView })
                }
              >
                <ChatInfo stored={q1stored} text={startQuiz[0].info} />
              </InView>
            </Delayed>
          </>
        )}
        {/*
         * EINS USER INPUT
         */}
        {!(q1correct || q1done) && (
          <Delayed time={1000}>
            <InputWrapper className="spacing-inner">
              <Preview active={q1value > 0}>
                <p>{`${q1value} Cent`}</p>
              </Preview>

              <div className="buttons">
                <RangeSlider
                  value={q1value}
                  onChange={(e) =>
                    dispatch({ type: 'Q1_INPUT', payload: e.target.value })
                  }
                />
                {!q1a1 && (
                  <SubmitButton
                    disabled={q1value === 0}
                    onClick={() => dispatch({ type: 'Q1_FIRSTSUBMIT' })}
                  />
                )}
                {q1a1 && (
                  <Delayed time={2000}>
                    <SubmitButton
                      disabled={q1value === 0}
                      onClick={() => dispatch({ type: 'Q1_SECONDSUBMIT' })}
                    />
                  </Delayed>
                )}
              </div>
            </InputWrapper>
          </Delayed>
        )}
      </div>
      {/*
       * ZWEI
       */}
      {(q1correct || q1stored) && (
        <div className="chats">
          <Delayed time={2000} stored={q2stored}>
            <ChatLeft text={startQuiz[1].question} stored={q2stored} />
          </Delayed>
          {q2a && (
            <ChatIllu>{q2a === 'Heizung' ? <Waerme /> : <Strom />}</ChatIllu>
          )}
          {q2a && (
            <Delayed time={2000} stored={q2stored}>
              <InView
                triggerOnce
                onChange={(inView) =>
                  dispatch({ type: 'Q2_STORE', payload: inView })
                }
              >
                <ChatLeft
                  text={
                    startQuiz[1].answer === q2a
                      ? 'Ja, richtig.'
                      : 'Es ist Heizung!'
                  }
                  stored={q2stored}
                />
              </InView>
            </Delayed>
          )}
          {/*
           * USER INPUT
           */}
          {!q2a && (
            <Delayed time={3000} stored={q2stored}>
              <InputWrapper>
                <div className="buttons">
                  <IlluButtonRight
                    onClick={() =>
                      dispatch({ type: 'Q2_SUBMIT', payload: 'Heizung' })
                    }
                  >
                    <Waerme size="8vw" />
                  </IlluButtonRight>
                  <IlluButtonLeft
                    onClick={() =>
                      dispatch({ type: 'Q2_SUBMIT', payload: 'Strom' })
                    }
                  >
                    <Strom size="8vw" />
                  </IlluButtonLeft>
                </div>
              </InputWrapper>
            </Delayed>
          )}
          {/*
           * ENDE
           */}
          {q2stored && (
            <>
              <Delayed time={1500}>
                <ChatLeft time={1500} text={endingQuestion[0]} />
              </Delayed>
              <Delayed time={5500}>
                <InView
                  triggerOnce
                  onChange={(inView) =>
                    inView && isClient && localStorage.removeItem('setOne')
                  }
                >
                  <ChatLeft text="Finde die Antwort und noch mehr Infos im Buch." />
                </InView>
              </Delayed>
              <Delayed time={7000}>
                <ChatLink text="Das Buch" internalLink="/buch/" />
              </Delayed>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SetOne;
