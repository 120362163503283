import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../components/organisms/layout';
import SEO from '../components/organisms/seo';
import SetOne from '../components/molecules/setOne';
import SetTwo from '../components/molecules/setTwo';
import SetThree from '../components/molecules/setThree';

const IndexPage = ({ data }) => {
  const { ee } = data.gradients.nodes[0];

  const questions = [<SetOne />, <SetTwo />, <SetThree />];
  const randomQuestion = Math.floor(Math.random() * questions.length);

  return (
    <Layout className="spacing-bottom" bgSrc={ee.image.asset.fluid}>
      <SEO />
      {questions[randomQuestion]}
    </Layout>
  );
};

export const query = graphql`
  query Gradients {
    gradients: allSanityGradients {
      nodes {
        ee {
          alt
          image {
            asset {
              fluid(maxWidth: 3600) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
