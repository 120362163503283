import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const ChatRight = ({ text, stored = false, id, key }) => (
  <StyledChat
    className="spacing-outer-left"
    key={key}
    id={id}
    initial={{ y: stored ? 0 : 500 }}
    animate={{ y: 0 }}
  >
    <p>{text}</p>
  </StyledChat>
);

const StyledChat = styled(motion.div)`
  border: var(--border);
  background: var(--user-color);
  p {
    padding: var(--v-spacing-S);
  }
`;

ChatRight.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  stored: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default ChatRight;
